import { PropsWithChildren, useState } from 'react';
import { DashboardLayoutContainer, DashboardLayoutContent, DashboardLayoutRoot, DashboardLayoutWrapper } from 'src/admin/components/adminLayout/AdminLayout';
import DashboardNavbar from 'src/admin/components/adminLayout/AdminNavbar';
import ChatSidebar from 'src/components/dashboard/chat/ChatSidebar';

const ChatLayout = ({ children }: PropsWithChildren<any>) => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar
        showBackToChat={false}
        sx={{
          background: '#0c62fb !important'
        }}
        onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
      />
      <ChatSidebar
        showSections={false}
        showBackToChatButton={false}
        onMobileClose={(): void => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent id="adminDashboard">
            {children}
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default ChatLayout;
