/*eslint-disable*/
const colors = {
  buttonHover: '#0c62fb',
  mainColor: '#1e2c37',
  white: '#fff',
  black: '#000000',
  darkGray2: '#858585',
  mainColorBright: '#858585',
  bodyText: '#3C3C3C',
  active: '#80CC28',
  darkBlue: '#0A0F34',
  darkBlueHover: '#121844',
  lightGrey: '#fff',
  errorDeactive: '#FF3D00',
  darkGrey: '#233746',
  littleDarkGrey: '#CBCBCB',
  lightGreyFiftyPercent: '#F3F3F3',
  bgBlackShade: '#fff',
  mainColorDark: '#53565a',
  mainColorLight: '#A4A4A4',
};
export default colors;