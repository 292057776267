import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setLLMModelType, setModelType } from 'src/slices/chat';
import { RootState, useDispatch } from 'src/store';

const RESPONSE_MODEL_TYPES = [
  {
    name: 'Jais-13b (SageMaker)',
    value: 'SageMaker',
  },
  // {
  //   name: 'Bedrock',
  //   value: 'Bedrock',
  // },
];

export const ModelTypeSelect = () => {
  const dispatch = useDispatch();
  const { llmModels, selectedLLMModel } = useSelector((state: RootState) => state.chat);

  // useEffect(() => {
  //   if (!selectedModelType) {
  //     dispatch(setModelType(RESPONSE_MODEL_TYPES[0].value));
  //   }
  // }, []);

  return (
    <Box sx={{ display: 'flex', flexGrow: 1, width: '300px' }}>
      <FormControl sx={{ width: '99%' }}>
        <InputLabel id="model-type-label">Select Model</InputLabel>
        <Select
          size="small"
          sx={{
            fontSize: '0.875rem',
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          }}
          value={selectedLLMModel}
          labelId="model-type-label"
          id="model-type-label-select"
          label="Select Model"
          onChange={(e: any) => {
            dispatch(setLLMModelType(e.target.value));
          }}
        >
          {llmModels && llmModels.map((res) => (
            <MenuItem
              sx={{ '&:hover': { backgroundColor: '#0c62fb' } }}
              value={res.id}
              key={res.name}
            >
              {res.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
