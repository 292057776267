/* eslint-disable */
// @ts-nocheck
import { MessagePayload, GetDocumentPayload } from 'src/types/chat';
import API from './apiInstance';

export const addMessage = (payload: MessagePayload) => API.post('/chat', payload);

export const getChatsBySessionId = (sessionId: number) => API.get(`/chat/session/${sessionId}`);

export const getConversationBySessionAndChatId = (sessionId: number, chatId: string) => API.get(`/chat/session/conversations/?session_id=${sessionId}&external_chat_id=${chatId}`);

export const getThreadsList = () => API.get('/chat/list');

export const getConversationById = (threadId: string) => API.get(`/chat/${threadId}`);

export const deleteThreadById = (threadId: string) => API.delete(`/chat/${threadId}`);

export const getLLMModels = () => API.get(`/llm/models`);

export const getDocumentDownloadLink = (payload: GetDocumentPayload) => API.post('/ingestion-metadata/download', payload);

export const addFeedBack = async (
  payload: FeedbackPayload,
  conv_id: number
) => {
  try {
    const response = await API.post(`/chat/feedback/${conv_id}`, payload);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      return error.response.data;
    } else {
      console.error('An error occurred:', error.message);
      throw error;
    }
  }
};

export const personalizedMessage = (payload: any) => API.post('/personalized/message', payload);

export const agentGuidance = (payload: any) => API.post('/agent_guidance/produce-agent-guidance', payload);