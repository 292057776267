/*eslint-disable*/
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ChatToolButton from '../ChatToolButton';
// import ChatLikeIcon from 'src/icons/chat-like-btn.svg';
// import ChatDislikeIcon from 'src/icons/chat-dislike-btn.svg';
import { addFeedBack } from 'src/api/chat';
import { getThread } from 'src/slices/chat';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import toast from 'react-hot-toast';

import UncheckedBox from 'src/icons/UncheckedBoxIcon.svg';
import CheckedBox from 'src/icons/CheckedBoxIcon.svg';
import {
  Theme,
  createStyles,
  experimentalStyled,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "#0c62fb",
    },
  });

const useStyles = makeStyles((theme) => ({
  submitFeedbackBtn: {
    color: '#3C3C3C',
    fontFamily: 'FS Albert Pro',
    fontSize: '12px',
    fontWeight: 400,
    height: '30px',
    borderRadius: '4px',
    border: '1px solid #EDEDED',
    marginRight: '10px',
    cursor: 'pointer',
  },
}));

const MyDialog = experimentalStyled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '.2rem',
  },
}));

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  btnCliked: string;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, btnCliked, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">
        <ChatToolButton
          sx={{
            pt: 0,
            pb: 0,
            mt: btnCliked == 'like' ? '-8px' : '0px',
          }}
          src={btnCliked == 'like' ? <ThumbUpIcon style={{ color: "#0c62fb" }} /> : <ThumbDownIcon style={{ color: "#0c62fb" }} />}
          onClick={() => { }}
        />
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "#0c62fb" }} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function ThumbsUpDownDialog({ open, setOpen }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { threadKey } = useParams();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isFeedbackSubmitPressed, setIsFeedbackSubmitPressed] = useState(false);
  const [feedbackData, setFeedbackData] = useState({
    thumbs_up: false,
    thumbs_down: false,
    feedback: '',
    inaccurate: false,
    inappropriate: false,
    helpful: false,
  });

  const handleClose = () => {
    setIsFeedbackSubmitPressed(false);
    setFeedbackData({
      thumbs_up: false,
      thumbs_down: false,
      feedback: '',
      inaccurate: false,
      inappropriate: false,
      helpful: false,
    });
    setOpen((prev) => {
      return { ...prev, open: false };
    });
  };

  const handleSubmitFeedback = async () => {
    const feedbackDataCopy = { ...feedbackData };
    feedbackDataCopy.thumbs_up = open.btnCliked == 'like' ? true : false;
    feedbackDataCopy.thumbs_down = open.btnCliked == 'dislike' ? true : false;
    setIsFeedbackSubmitPressed(true);
    if (feedbackDataCopy.feedback.trim() !== '') {
      setSubmitDisabled(true);
      const data = await addFeedBack(feedbackDataCopy, open.msgId);
      await dispatch(getThread(threadKey));
      setSubmitDisabled(false);
      setOpen((prev) => {
        return { open: false };
      });
    } else {
      toast.error('Feedback is required', { id: 'no-text-error' });
    }
  };

  return (
    <div>
      <MyDialog
        open={open.open}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle
          id="form-dialog-title"
          onClose={handleClose}
          btnCliked={open.btnCliked}
        // sx={{ display: 'flex', alignItems: 'center' }}
        >
          {/* <ChatToolButton
            sx={{
              pt: 0,
              pb: 0,
              mt: open.btnCliked == 'like' ? '-8px' : '0px',
            }}
            src={open.btnCliked == 'like' ? <ThumbUpIcon /> : <ThumbDownIcon />}
            onClick={() => {}}
          /> */}
          Provide additional feedback
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              autoFocus
              margin="dense"
              id="feedback"
              label="Feedback"
              placeholder="What do you like about the response?"
              type="feedback"
              fullWidth
              multiline
              rows={4}
              required // Make the TextField required
              error={feedbackData.feedback.trim() === '' && isFeedbackSubmitPressed}
              onChange={(e) => {
                setFeedbackData((prev) => {
                  return { ...prev, feedback: e.target.value.trim() };
                });
              }}
            />
            {open.btnCliked == 'like' ? null : (
              <>
                {' '}
                <FormControlLabel
                  style={{ width: 'fit-content' }}
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setFeedbackData((prev) => {
                          return { ...prev, inaccurate: e.target.checked };
                        });
                      }}
                      checked={feedbackData.inaccurate}
                      name="inaccurate"
                      icon={<img src={UncheckedBox} height={18} />}
                      checkedIcon={<img src={CheckedBox} height={18} />}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontSize: '1rem',
                        color: '#060606',
                        textDecoration: 'underlined',
                      }}
                    >
                      This is harmful / unsafe
                    </Typography>
                  }
                />
                <FormControlLabel
                  style={{ width: 'fit-content' }}
                  control={
                    <Checkbox
                      checked={feedbackData.inappropriate}
                      onChange={(e) => {
                        setFeedbackData((prev) => {
                          return { ...prev, inappropriate: e.target.checked };
                        });
                      }}
                      name="inappropriate"
                      icon={<img src={UncheckedBox} height={18} />}
                      checkedIcon={<img src={CheckedBox} height={18} />}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontSize: '1rem',
                        color: '#060606',
                        textDecoration: 'underlined',
                      }}
                    >
                      This isn't true
                    </Typography>
                  }
                />
                <FormControlLabel
                  style={{ width: 'fit-content' }}
                  control={
                    <Checkbox
                      checked={feedbackData.helpful}
                      onChange={(e) => {
                        setFeedbackData((prev) => {
                          return { ...prev, helpful: e.target.checked };
                        });
                      }}
                      name="helpful"
                      icon={<img src={UncheckedBox} height={18} />}
                      checkedIcon={<img src={CheckedBox} height={18} />}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontSize: '1rem',
                        color: '#060606',
                        textDecoration: 'underlined',
                      }}
                    >
                      This isn't helpful
                    </Typography>
                  }
                />
              </>
            )}
          </Box>
        </DialogContent>

        <Divider />
        <DialogActions>
          {/* <button
            className={classes.submitFeedbackBtn}
            onClick={handleSubmitFeedback}
          >
            Submit feedback
          </button> */}
          <Button
            size="small"
            variant="contained"
            disabled={submitDisabled}
            className={`btn-submitFeedback`}
            // sx={{
            //   width: '171px',
            //   height: '48px',
            //   flexShrink: 0,
            //   borderRadius: '50px',
            //   border: '1px solid #0c62fb',
            //   background: '#FFF',
            //   color: '#0c62fb',
            //   fontSize: '18px',
            //   fontWeight: 400,
            // }}
            onClick={handleSubmitFeedback}
          >
            Submit feedback
          </Button>
        </DialogActions>
      </MyDialog>
    </div>
  );
}
