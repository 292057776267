/*eslint-disable*/
import { FC, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import { Divider } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { personalizedMessage } from 'src/api/chat';
import Backdrop from '@mui/material/Backdrop';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useDispatch } from 'src/store';
import { setSettings } from '../../slices/metadata';

interface SimpleDialogProps {
	open: boolean;
	onClose: () => void;
}

const Getir2Page: FC = () => {
	const [loading, setLoading] = useState<boolean>(false)
	const [open, setOpen] = useState<boolean>(false)
	const [activeID, setActiveID] = useState<number | undefined>()
	const [activeObject, setActiveObject] = useState<any>({})
	const [responseData, setResponseData] = useState<any>({})

	const [promoCode, setPromoCode] = useState<string>('ALL_YYS_YUZDE_50_400TL_V26')
	const [promoID, setPromoID] = useState<string>('65d33f90b5a7402f35f17c33')
	const [promoMechanic, setPromoMechanic] = useState<string>('1')
	const [promoType, setPromoType] = useState<string>('1')
	const [objective, setObjective] = useState<string>('Upsell')
	const [occasionTR, setOccasionTR] = useState<string>('Doğum Günü')
	const [occasionEN, setOccasionEN] = useState<string>('Birthday')

	const [weekpartTR, setWeekpartTR] = useState<string>('Haftaiçi')
	const [weekpartEN, setWeekpartEN] = useState<string>('Weekday')
	const [daypartTR, setDaypartTR] = useState<string>('Gece')
	const [daypartEN, setDaypartEN] = useState<string>('Night')
	const [segments, setSegments] = useState<string>('1,2,3,4')
	const [excludeSegments, setExcludeSegments] = useState<string>('0')

	const [domainTypes, setDomainTypes] = useState<string>('0')
	const [discountAmount, setDiscountAmount] = useState<string>('0')
	const [maxItemCount, setMaxItemCount] = useState<string>('2')
	const [minOrderPrice, setMinOrderPrice] = useState<string>('100')
	const [products, setProducts] = useState<string>('59c9108e169b820004c543c8,5f5871f527265b73a687609d,60509958439c3e56a578fef0')
	const [countryCode, setCountryCode] = useState<string>('TR')
	const [languages, setLanguages] = useState<string>('TR,EN')
	const [deliveryFee, setDeliveryFee] = useState<boolean>(false)

	const dispatch = useDispatch()
	dispatch(setSettings(false))
	useEffect(() => { return () => { dispatch(setSettings(true)) } }, [])

	const handleClickOpen = () => { setOpen(true) }

	const handleClose = () => { setOpen(false) }

	const SimpleDialog = (props: SimpleDialogProps) => {
		const { onClose, open } = props;
		const handleClose = () => { onClose() }
		return (
			<Dialog onClose={handleClose} open={open} maxWidth={'sm'} fullWidth={true}>
				<DialogTitle>Clients</DialogTitle>
				<List sx={{ pt: 0 }}>
					{activeObject.subSegmentClients?.map((data) => { return <ListItem><ListItemText>{data}</ListItemText></ListItem> })}
				</List>
			</Dialog>
		);
	}

	const formData = {
		"promoData": {
			"promoCode": promoCode,
			"promoId": promoID,
			"promoMechanic": Number(promoMechanic),
			"promoType": Number(promoType),
			"objective": objective,
			"occasion": { "tr": occasionTR, "en": occasionEN },
			"weekpart": { "tr": weekpartTR, "en": weekpartEN },
			"daypart": { "tr": daypartTR, "en": daypartEN },
			"segments": segments.split(',').map(Number),
			"excluedeSegments": excludeSegments.split(',').map(Number),
			"domainTypes": domainTypes.split(',').map(Number),
			"benefits": {
				"discountAmount": Number(discountAmount),
				"maxItemCount": Number(maxItemCount),
				"minOrderTotalPrice": Number(minOrderPrice),
				"doNotChargeDeliveryFee": deliveryFee,
				"products": products.split(',')
			},
			"condition": {
				"products": []
			},
			"weights": {
				"Persona": 1,
				"Product": 1,
				"Churn": 1,
				"ShopperMission": 1
			}
		},
		"countryCode": countryCode,
		"languages": languages.split(','),
		"isRegenerated": false,
		"source": "commsAINotIfGeneration"
	}

	const submit = async () => {
		// validate form
		setLoading(true)
		try {
			const resp: any = await personalizedMessage(formData);
			if (resp.data.subSegmentsMessages && resp.data.subSegmentsMessages.length) {
				setResponseData(resp.data)
				const sortedData = resp.data.subSegmentsMessages.sort((a, b) => a.subSegmentId - b.subSegmentId);
				setActiveObject(sortedData[0])
				setActiveID(sortedData[0].subSegmentId)
			}
		} catch (error) {
			console.error('An error occurred:', error.message);
			throw error;
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (activeID && responseData) {
			const filteredObject = responseData.subSegmentsMessages.filter((data) => { return data.subSegmentId === activeID })
			if (filteredObject.length) {
				setActiveObject(filteredObject[0])
			}
		}
	}, [activeID]);

	const sortedResponse = () => {
		return responseData.subSegmentsMessages.sort((a, b) => a.subSegmentId - b.subSegmentId);
	}

	const getSegmentIDs = () => { return sortedResponse().map((data) => data.subSegmentId) }

	const languageHTML = () => {
		let tr = <Grid item xs={11} lg={6} style={{ textAlign: 'left' }}><b className='heading'>Messages in TR</b></Grid>
		let en = <Grid item xs={11} lg={6} style={{ textAlign: 'left' }}><b className='heading'>Messages in EN</b></Grid>
		if (activeObject.tr && activeObject.en) {
			return <>{tr}{en}{<Grid item xs={12}><Divider style={{ background: 'black', height: '2px' }} /></Grid>}{activeObject.tr?.map((data, index) => {
				return (<>
					<Grid item xs={11} lg={6} style={{ textAlign: 'left' }}>
						<b>Title:</b><span>{activeObject?.tr[index]?.title}</span>
						<br /><br />
						<span>{activeObject?.tr[index]?.message}</span>
					</Grid>
					<Grid item xs={11} lg={6}>
						<b>Title:</b><span>{activeObject?.en[index]?.title}</span>
						<br /><br />
						<span>{activeObject?.en[index]?.message}</span>
					</Grid>
					<Grid item xs={12}>
						<Divider style={{ background: 'black' }} />
					</Grid>
				</>
				)
			})}
			</>
		} else if (activeObject.tr) {
			let tr = <Grid item xs={11} lg={6} style={{ textAlign: 'left' }}><b className='heading'>Messages in TR</b></Grid>
			return <>{tr}{<Grid item xs={12}><Divider style={{ background: 'black', height: '2px' }} /></Grid>}{activeObject.tr?.map((data, index) => {
				return (<>
					<Grid item xs={11} lg={6} style={{ textAlign: 'left' }}>
						<b>Title:</b><span>{activeObject?.tr[index]?.title}</span>
						<br /><br />
						<span>{activeObject?.tr[index]?.message}</span>
					</Grid>
					<Grid item xs={12}>
						<Divider style={{ background: 'black' }} />
					</Grid>
				</>
				)
			})}
			</>

		} else if (activeObject.en) {
			let en = <Grid item xs={11} lg={6} style={{ textAlign: 'left' }}><b className='heading'>Messages in EN</b></Grid>
			return <>{en}{<Grid item xs={12}><Divider style={{ background: 'black', height: '2px' }} /></Grid>}{activeObject.en?.map((data, index) => {
				return (<>
					<Grid item xs={11} lg={6}>
						<b>Title:</b><span>{activeObject?.en[index]?.title}</span>
						<br /><br />
						<span>{activeObject?.en[index]?.message}</span>
					</Grid>
					<Grid item xs={12}>
						<Divider style={{ background: 'black' }} />
					</Grid>
				</>
				)
			})}
			</>
		}
	}

	return (
		<>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<div className='home-box-container'>
				<Container maxWidth="md" style={{ height: 'inherit', marginBottom: '25px' }}>
					<Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="center" alignItems="center" style={{ paddingBottom: '25px' }}>
						<Grid item xs={11} lg={10}>
							<h1 style={{ marginBottom: 0 }}>Personalized Messages</h1>
						</Grid>
						<Grid item xs={11} lg={10}>
							<hr />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={promoCode} onChange={e => { setPromoCode(e.target.value) }} label="Promo Code" size="small" fullWidth />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={weekpartTR} onChange={e => { setWeekpartTR(e.target.value) }} label="Weekpart TR" size="small" fullWidth />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={promoID} onChange={e => { setPromoID(e.target.value) }} label="Promo ID" size="small" fullWidth />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={weekpartEN} onChange={e => { setWeekpartEN(e.target.value) }} label="Weekpart EN" size="small" fullWidth />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={promoMechanic} onChange={e => { setPromoMechanic(e.target.value) }} label="Promo Mechanic" size="small" fullWidth type="number" />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={daypartTR} onChange={e => { setDaypartTR(e.target.value) }} label="Daypart TR" size="small" fullWidth />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={promoType} onChange={e => { setPromoType(e.target.value) }} label="Promo Type" size="small" fullWidth type="number" />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={daypartEN} onChange={e => { setDaypartEN(e.target.value) }} label="Daypart EN" size="small" fullWidth />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={objective} onChange={e => { setObjective(e.target.value) }} label="Objective" size="small" fullWidth />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={segments} onChange={e => { setSegments(e.target.value) }} label="Segments" size="small" fullWidth />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={occasionTR} onChange={e => { setOccasionTR(e.target.value) }} label="Occasion TR" size="small" fullWidth />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={excludeSegments} onChange={e => { setExcludeSegments(e.target.value) }} label="Exclude Segments" size="small" fullWidth />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={occasionEN} onChange={e => { setOccasionEN(e.target.value) }} label="Occasion EN" size="small" fullWidth />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={domainTypes} onChange={e => { setDomainTypes(e.target.value) }} label="Domain Types" size="small" fullWidth />
						</Grid>
						<Grid item xs={12} lg={10}>
							<Divider />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={discountAmount} onChange={e => { setDiscountAmount(e.target.value) }} label="Discount Amount" size="small" fullWidth type="number" />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={maxItemCount} onChange={e => { setMaxItemCount(e.target.value) }} label="Max Item Count" size="small" fullWidth type="number" />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={minOrderPrice} onChange={e => { setMinOrderPrice(e.target.value) }} label="Min Order Price" size="small" fullWidth type="number" />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={products} onChange={e => { setProducts(e.target.value) }} label="Products" size="small" fullWidth />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={countryCode} onChange={e => { setCountryCode(e.target.value) }} defaultValue="TR" label="Country Code" size="small" fullWidth />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={languages} onChange={e => { setLanguages(e.target.value) }} label="Languages" size="small" fullWidth />
						</Grid>
						<Grid item xs={11} lg={10}>
							<FormControlLabel control={<Checkbox value={deliveryFee} onChange={e => { setDeliveryFee(e.target.checked) }} />} label="Do Not Charge Delivery Fee" />
						</Grid>
						<Grid item xs={11} lg={10}>
							<Button className='nbs-btn' variant="contained" onClick={submit}>Submit</Button>
						</Grid>
					</Grid>
					{(responseData.subSegmentsMessages && responseData.subSegmentsMessages.length) &&
						<>

							<Grid item xs={11} lg={11}>
								<hr style={{ border: '2px solid black', marginBottom: '25px' }} />
							</Grid>
							<Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="center" alignItems="center" style={{ paddingBottom: '25px' }}>
								{getSegmentIDs().map((data) => (
									<Grid item xs='auto' onClick={() => { setActiveID(data) }}>
										<span className={`segment-box ${(data === activeID) ? 'active' : null}`}>Sub-segment: {data}</span>
									</Grid>
								))}
							</Grid>
							<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="center" alignItems="center" style={{ paddingBottom: '25px' }}>
								<Grid item xs={11} lg={10}><b className='heading'><u>Sub-Segment ID {activeObject.subSegmentId}</u></b></Grid>
								<Grid item xs={11} lg={10}>{activeObject.subSegmentDescription}</Grid>
								<Grid item xs={11} lg={6}>
									<Button variant="outlined" onClick={handleClickOpen} className='button-class'>
										Show Clients
									</Button>
									<SimpleDialog open={open} onClose={handleClose} />
								</Grid>
							</Grid>
							<Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="center" alignItems="center" style={{ paddingBottom: '25px', textAlign: 'left' }}>
								{languageHTML()}
							</Grid>
						</>}
				</Container>
			</div>
		</>
	);
};

export default Getir2Page;
