import { useState, useEffect, useCallback } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography
} from '@material-ui/core';
import useMounted from '../../../hooks/useMounted';
import useSettings from '../../../hooks/useSettings';
import ChevronRightIcon from '../../../icons/ChevronRight';
import PlusIcon from '../../../icons/Plus';
import gtm from '../../../lib/gtm';
import type { IURL } from '../../../types/url';
import { listURLs } from 'src/api/url';
import ConfigurationsListTable from './ConfigurationsListTable';
import ConfigurationsAdd from './ConfigurationsAdd';

const PAGE_SIZE = 5;

const ConfigurationsList: FC = () => {
  const mounted = useMounted();
  const { settings } = useSettings();
  const [configurations, setConfigurations] = useState<{ results: IURL[]; count: number }>({
    results: [],
    count: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [addConfig, setAddConfig] = useState(false);
  const [selectedURLs, setSelectedURLs] = useState<number[]>([]);
  const [selectURLObject, setSelectURLObject] = useState<IURL>(null);
  const [tableParams, setTableParams] = useState<{ page: number; limit: number; query: string }>({
    page: 0,
    limit: PAGE_SIZE,
    query: ''
  });

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const getConfigurations = useCallback(async (pageNum = 0, limit = 5, query = '') => {
    try {
      setLoading(true);
      const queryString = `limit=${limit}&offset=${pageNum}&name=${query}`;
      const res = await listURLs(queryString);

      if (mounted.current) {
        setConfigurations({
          results: res?.data.results ?? [],
          count: res?.data.count ?? 0,
        });
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }, [mounted]);

  const editButtonClicked = useCallback((obj) => {
    setAddConfig(true);
    setSelectURLObject(obj);
  }, [mounted]);

  useEffect(() => {
    getConfigurations();
  }, [getConfigurations]);

  return (
    <>
      <Helmet>
        <title>Dashboard: Configurations | NBS Chatbot</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Configurations List
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Generative AI
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Configurations
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  startIcon={<PlusIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={() => setAddConfig(true)}
                >
                  Add Configuration
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <ConfigurationsListTable
              configurations={configurations}
              getConfigurations={getConfigurations}
              editButtonClicked={editButtonClicked}
              loading={loading}
              tableParams={tableParams}
              setTableParams={setTableParams}
              selectedURLs={selectedURLs}
              setSelectedURLs={setSelectedURLs}
            />
          </Box>
        </Container>
      </Box>
      {addConfig && (
        <ConfigurationsAdd
          open={addConfig}
          data={selectURLObject}
          onCancel={() => {
            setAddConfig(false);
            setSelectURLObject(null);
          }}
          onSuccess={(value) => {
            if (value) {
              setAddConfig(false);
              setSelectURLObject(null);
              setTableParams((prevParams) => ({ ...prevParams, page: 0, query: '' }));
              getConfigurations(0, tableParams.limit, '');
            }
          }}
        />
      )}
    </>
  );
};

export default ConfigurationsList;
