/*eslint-disable*/
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import gtm from '../../lib/gtm';
import { useTranslation } from 'react-i18next';
import '../../styles/landing.css';
import { AppDispatch, useSelector } from 'src/store';
import { getThreads, getLLMModelsList } from 'src/slices/chat';
import { useDispatch } from 'react-redux';
// import SelectModel from 'src/components/dashboard/SelectModel';
import { DashboardLayoutContainer, DashboardLayoutContent, DashboardLayoutRoot, DashboardLayoutWrapper } from 'src/admin/components/adminLayout/AdminLayout';
import DashboardNavbar from 'src/admin/components/adminLayout/AdminNavbar';

import './index.css';

type Props = {
	content: JSX.Element
}

const LandingHomePage: FC<Props> = (props) => {
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();
	const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);

	useEffect(() => {
		gtm.push({ event: 'page_view' });
		dispatch(getThreads());
		dispatch(getLLMModelsList());
	}, []);

	return (
		<DashboardLayoutRoot>
			<DashboardNavbar
				showBackToChat={false}
				sx={{ background: '#0c62fb !important' }}
				onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
			/>
			<DashboardLayoutWrapper style={{ paddingLeft: 0 }}>
				<Helmet>
					<title>{t("askNBS")}</title>
				</Helmet>
				{props.content}
			</DashboardLayoutWrapper>
		</DashboardLayoutRoot>

	);
};

export default LandingHomePage;
