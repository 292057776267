/*eslint-disable*/
import { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { Theme, experimentalStyled } from '@material-ui/core/styles';
import DashboardNavbar from './AdminNavbar';
import DashboardSidebar from './AdminSidebar';
import '../../styles/admin.css';
import { useDispatch } from 'src/store';
import { getDepartmentsList } from 'src/slices/metadata';
import { useMediaQuery } from '@material-ui/core';

interface DashboardLayoutProps {
  children?: ReactNode;
}

export const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

export const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '0px',
  backgroundColor: '#FFF',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '220px',
    marginTop: '80px',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '80px',
  },
}));

export const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  backgroundColor: '#FFF',
  overflow: 'hidden',
  marginRight: '20px',
  marginLeft: '20px',
});

export const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  width: '100%',
  // overflow: 'auto',
  backgroundColor: '#FFF',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
});

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] =
    useState<boolean>(false);
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const dispatch = useDispatch();
  // useEffect(() => {
  //   (async () => {
  //     await dispatch(getDepartmentsList());
  //   })();
  // }, []);

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar
        onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
      />
      <DashboardSidebar
        showBackToChatButton={mdDown}
        onMobileClose={(): void => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent id="adminDashboard">
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
