import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { saveDepartmentsList } from 'src/store/localStorage';
import i18n from 'src/i18n';
import { getDepartments } from 'src/admin/api/metadata';

interface Metadata {
  departments: any,
  byId: { [key: string]: string };
  showSettings?: boolean
}

const initialState: Metadata = {
  departments: null,
  byId: null,
  showSettings: true
};

const slice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    setDepartments(state: Metadata, action: PayloadAction<any>): void {
      state.departments = action.payload;
      const departmentByIds = {};
      state.departments?.forEach((d) => {
        departmentByIds[d.id] = d.name;
      });

      state.byId = departmentByIds;
    },
    clearDepartments(state: Metadata, action: PayloadAction<any>): void {
      state.departments = action.payload;
      state.byId = null;
    },
    setSettings(state: Metadata, action: PayloadAction<any>): void {
      state.showSettings = action.payload;
    },
  },
});

export const { reducer } = slice;

export const setDepartments = (data): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setDepartments(data));
  saveDepartmentsList(data);
};

export const getDepartmentsList = (): AppThunk => async (dispatch): Promise<void> => {
  try {
    const response = await getDepartments();
    const departments = response?.data;
    dispatch(setDepartments(departments));
  } catch (error) {
    throw new Error(i18n.t('departmentsFetchError'));
  }
};

export const clearDepartments = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.clearDepartments(null));
  saveDepartmentsList(null);
};

export const setSettings = (data): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setSettings(data));
};

export default slice;
