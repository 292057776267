/*eslint-disable*/
import { useNavigate } from 'react-router-dom';
import type { FC } from 'react';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@mui/material/IconButton';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import EmailIcon from '@mui/icons-material/Email';

const LandingHomePage: FC = () => {
	const navigate = useNavigate();
	return (
		<div className='home-parent'>
			<div className='home-box-container-0'>
				<h2 style={{ fontSize: '28px' }}>Welcome to NorthBay</h2>
				<hr />
				<h4 style={{ fontSize: '20px' }}>Please seclect your desired option to proceed further.</h4>
			</div>
			<div className='home-box-container'>
				<CardContent className='home-box' onClick={() => { navigate('/home') }}>
					<IconButton className='home-box-button'><TipsAndUpdatesIcon /></IconButton>
					<span className='home-box-1'></span>
					<div className='home-box-2'><span>NorthBay U-Ask</span></div>
				</CardContent>
				<CardContent className='home-box' onClick={() => { navigate('/agent-guidance') }}>
					<IconButton className='home-box-button'><HeadphonesIcon /></IconButton>
					<span className='home-box-1'></span>
					<div className='home-box-2'><span>Agent Guidance</span></div>
				</CardContent>
				<CardContent className='home-box' onClick={() => { navigate('/personalized-messages') }}>
					<IconButton className='home-box-button'><EmailIcon /></IconButton>
					<span className='home-box-1'></span>
					<div className='home-box-2'><span>Personalized Messages</span></div>
				</CardContent>
			</div>
		</div>
	);
};

export default LandingHomePage;
