/*eslint-disable*/
import type { FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Avatar, Box, Button, IconButton, Toolbar } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import type { AppBarProps } from '@material-ui/core';
import MenuIcon from '../../../icons/Menu';
import LOGONBS from 'src/icons/logoNBS.svg';
import NBSLOGO from 'src/icons/logoNBS-black.svg';
import '../../styles/admin.css'
import { APP_MAIN_URL } from 'src/config';
import MainNav from 'src/components/navigation/MainNav';
import colors from 'src/theme/colors';
import { ChevronLeft } from '@material-ui/icons';

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
  showBackToChat?: boolean;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: '#0c62fb',
    // borderBottom: '1px solid #CCC',
    boxShadow: '4px 1px 7px -3px rgba(0,0,0,0.2)',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  zIndex: theme.zIndex.drawer + 100 + 50,
}));

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, showBackToChat = true, ...other } = props;
  const navigate = useNavigate();

  const routeChange = () => {
    try {
      navigate('/landing');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 50 }}>
        <Box sx={{ display: 'flex' }}>
          <IconButton
            color="inherit"
            onClick={onSidebarMobileOpen}
            sx={{
              display: {
                lg: 'none',
              },
              color: 'white',
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <RouterLink
            to={`${APP_MAIN_URL}/landing`}
            // to=""
            // onClick={(ev: any) => ev.preventDefault()}
            style={{ cursor: "pointer" }}>
            <Avatar className='backgroundTransparent'
              variant="square"
              sx={{
                height: 80,
                width: 220,
              }}
            >
              <img className='logo-white' src={NBSLOGO} alt={'NBS U-Ask'} />
            </Avatar>
          </RouterLink>
        </Box>
        {showBackToChat && (<Box
          sx={{
            flexGrow: 1,
          }}
        >
          <Button
            color="primary"
            className='backToChat btnPrimary'
            variant="outlined"
            onClick={routeChange}
            startIcon={<ChevronLeft />}
            sx={{
              marginRight: {
                sm: 1,
                md: 0,
              }
            }}
          >
            BACK TO CHAT
          </Button>
        </Box>)}
        <Box sx={{ mr: '1.5rem', position: 'relative', right: 0 }}>
          <MainNav color="#FFF" />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
