/*eslint-disable*/
import { FC, useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import { Divider } from '@mui/material'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress';
import { agentGuidance } from 'src/api/chat'
import Backdrop from '@mui/material/Backdrop'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useDispatch } from 'src/store';
import { setSettings } from '../../slices/metadata';

const Getir1Page: FC = () => {
	const [loading, setLoading] = useState<boolean>(false)
	const [responseData, setResponseData] = useState<any>({})

	const [vertical, setVertical] = useState<string>('market')
	const [intent, setIntent] = useState<string | number>('malfunctioned product')
	const [summary, setSummary] = useState<string>('The customer states that the package of the product he purchased has been opened')
	const [process, setProcess] = useState<string>('çözüm odaklı süreç')
	const [productName, setProductName] = useState<string>('Tahsildaroğlu Dilimli Klasik İnek Beyaz Peyniri ( 450 g)')
	const [orderStatus, setOrderStatus] = useState<string>('PREPARING');
	const [productCategory, setProductCategory] = useState<string>('Milk & Dairy')
	const [productSubCategory, setProductSubCategory] = useState<string>('Cheese')
	const [productCount, setProductCount] = useState<string>('1')
	const [basketPrice, setBasketPrice] = useState<string>('149.95')
	const [chargedAmount, setChargedAmount] = useState<string>('67.2387')
	const [isBundled, setIsBundled] = useState<boolean>(false)
	const [minutesSinceCheckout, setMinutesSinceCheckout] = useState<string>('25')

	const dispatch = useDispatch()
	dispatch(setSettings(false))
	useEffect(() => { return () => { dispatch(setSettings(true)) } }, [])
	
	const formData = {
		"vertical": vertical,
		"summary": summary,
		"intent": [intent],
		"process": process,
		"customer_order_info": {
			"minutes_since_checkout": minutesSinceCheckout ? parseInt(minutesSinceCheckout) : 0,
			"order_status": orderStatus,
			"checkout_date": "2023-12-01 10:27:13",
			"is_bundle": isBundled,
			"basket_products": [
				{
					"product_name": productName,
					"count": productCount ? parseInt(productCount) : 0,
					"category_name": productCategory,
					"subcategory_name": productSubCategory,
					"basketprice": basketPrice ? parseFloat(basketPrice) : 0,
					"charged_amount": chargedAmount ? parseFloat(chargedAmount) : 0,
				}
			]
		}
	}


	const submit = async () => {
		// validate form
		setLoading(true)
		try {
			const resp: any = await agentGuidance(formData);
			setResponseData(resp.data)
		} catch (error) {
			console.error('An error occurred:', error.message);
			throw error;
		} finally {
			setLoading(false)
		}
	}

	const responseHTML = () => {
		let guidelineToAgent = <></>
		let messageToCustomer = <></>
		if (responseData && responseData.ai_response.guideline_to_agent) {
			guidelineToAgent = (
				<Grid item xs={11} lg={5} style={{ textAlign: 'left' }}>
					<b className='heading'>Guideline To Agent</b>
					<br /><br />
					{responseData.ai_response?.guideline_to_agent?.map((data) => {
						return (<><span>{data}</span><br /><br /></>)
					})}
				</Grid>
			)
		}
		if (responseData && responseData.ai_response.message_to_customer) {
			messageToCustomer = (
				<Grid item xs={11} lg={5} style={{ textAlign: 'left' }}>
					<b className='heading'>Message To Customer</b>
					<br /><br />
					{responseData.ai_response?.message_to_customer?.map((data) => {
						return (<><span>{data}</span><br /><br /></>)
					})}
				</Grid>
			)
		}

		return <>{guidelineToAgent}{messageToCustomer}</>

	}

	return (
		<>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<div className='home-box-container'>
				<Container maxWidth="md" style={{ height: 'inherit' }}>
					<Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="center" alignItems="spaceBetween" style={{ paddingBottom: '25px' }}>
						<Grid item xs={11} lg={10}>
							<h1 style={{ marginBottom: 0 }}>Agent Guidance</h1>
						</Grid>
						<Grid item xs={11} lg={10}>
							<hr />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={vertical} onChange={e => { setVertical(e.target.value) }} label="Vertical" size="small" fullWidth />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={intent} onChange={e => { setIntent(e.target.value) }} className='text-left' label="Intent" size="small" fullWidth select >
								<MenuItem value={"malfunctioned product"}>malfunctioned product</MenuItem>
								<MenuItem value={"burnt/overheated product"}>burnt/overheated product</MenuItem>
								<MenuItem value={"skt product"}>skt product</MenuItem>
								<MenuItem value={"stale product"}>stale product</MenuItem>
								<MenuItem value={"wrong item"}>wrong item</MenuItem>
								<MenuItem value={"heat issue: product is warm"}>heat issue: product is warm</MenuItem>
								<MenuItem value={"melted product"}>melted product</MenuItem>
								<MenuItem value={"missing grammage"}>missing grammage</MenuItem>
								<MenuItem value={"melted product"}>melted product</MenuItem>
								<MenuItem value={"missing item"}>missing item</MenuItem>
								<MenuItem value={"damaged or broken item"}>damaged or broken item</MenuItem>
								<MenuItem value={"rotten or spoilt item"}>rotten or spoilt item</MenuItem>
							</TextField>
						</Grid>
						<Grid item xs={11} lg={10}>
							<TextField value={summary} onChange={e => { setSummary(e.target.value) }} label="Summary" size="small" fullWidth />
						</Grid>
						<Grid item xs={11} lg={10}>
							<TextField value={process} onChange={e => { setProcess(e.target.value) }} label="Process" size="small" fullWidth />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={orderStatus} onChange={e => { setOrderStatus(e.target.value) }} className='text-left' label="Order Status" size="small" fullWidth select >
								<MenuItem value={"ON_WAY"}>On Way</MenuItem>
								<MenuItem value={"PREPARING"}>Preparing</MenuItem>
								<MenuItem value={"ON_ADDRESS"}>On Address</MenuItem>
							</TextField>
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={minutesSinceCheckout} onChange={e => { setMinutesSinceCheckout(e.target.value) }} label="Minutes Since Checkout" size="small" fullWidth type="number" />
						</Grid>
						<Grid item xs={11} lg={5}>
							<FormControlLabel control={<Checkbox value={isBundled} onChange={e => { setIsBundled(e.target.checked) }} />} label="Is bundle" />
						</Grid>
						<Grid item xs={12} lg={10}>
							<Divider>Product</Divider>
						</Grid>
						<Grid item xs={11} lg={10}>
							<TextField value={productName} onChange={e => { setProductName(e.target.value) }} label="Product Name" size="small" fullWidth />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={productCategory} onChange={e => { setProductCategory(e.target.value) }} label="Product Category" size="small" fullWidth />
						</Grid>
						<Grid item xs={11} lg={5}>
							<TextField value={productSubCategory} onChange={e => { setProductSubCategory(e.target.value) }} label="Product Sub-Category" size="small" fullWidth />
						</Grid>
						<Grid item xs={11} lg={3.33}>
							<TextField value={productCount} onChange={e => { setProductCount(e.target.value) }} label="Product Count" size="small" fullWidth type="number" />
						</Grid>
						<Grid item xs={11} lg={3.33}>
							<TextField value={basketPrice} onChange={e => { setBasketPrice(e.target.value) }} label="Basket Price" size="small" fullWidth type="number" inputProps={{ step: 0.01 }} />
						</Grid>
						<Grid item xs={11} lg={3.33}>
							<TextField value={chargedAmount} onChange={e => { setChargedAmount(e.target.value) }} label="Charged Amount" size="small" fullWidth type="number" inputProps={{ step: 0.01 }} />
						</Grid>
						<Grid item xs={11} lg={10}>
							<Button className='nbs-btn' variant="contained" onClick={submit}>Submit</Button>
						</Grid>
					</Grid>
					{responseData && responseData.ai_response &&
						<Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} justifyContent="center" alignItems="start" style={{ paddingBottom: '25px', textAlign: 'left' }}>
							<Grid item xs={12} lg={10}>
								<Divider style={{ marginBottom: '10px', marginTop: '10px' }}>Response</Divider>
							</Grid>
							{responseHTML()}
						</Grid>
					}
				</Container>
			</div>
		</>
	);
};

export default Getir1Page;
