/*eslint-disable*/
import { useRef, useState } from 'react';
import type { ChangeEvent, FC, KeyboardEvent } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Theme,
  Tooltip,
  createStyles,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { useSelector } from 'src/store';
import { isEmpty, transform } from 'lodash';
import { useTranslation } from 'react-i18next';
//import SendButton from 'src/icons/NBS-Send';
import SendButton from 'src/icons/SendButton';
import colors from 'src/theme/colors';

interface ChatMessageAddProps {
  disabled?: boolean;
  onSend?: (value: string) => void;
}

const CssTextField = withStyles((theme: Theme) => ({
  root: {
    // '& label.Mui-focused': {
    //   color: '#A4A4A4',
    //   backgroundColor: '#ffffff'
    // },
    // '& .MuiInput-underline:after': {
    //   borderBottomColor: '#9B9B9B',
    // },
    '& .MuiOutlinedInput-root': {
      color: '#53565a',
      padding: '0px 21px',
      [theme.breakpoints.down(1400)]: {
        padding: '0px 18px',
      },
      [theme.breakpoints.down(1200)]: {
        padding: '0px 15px',
      },
      [theme.breakpoints.down(760)]: {
        padding: '0px 15px',
      },
      borderColor: '#9B9B9B',
      '& fieldset': {
        borderRadius: '5px',
      },
      '&:hover fieldset': {
        borderColor: '#9B9B9B',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#9B9B9B',
      },
    },
  },
}))(TextField);

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: 300,
    margin: 100,
  },
  //style for font size
  resize: {
    fontSize: '0.875rem',
    [theme.breakpoints.down(1400)]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down(1200)]: {
      fontSize: '0.875rem',
    },
    [theme.breakpoints.down(760)]: {
      fontSize: '0.875rem',
    },
    letterSpacing: '0.36px',
    fontWeight: 400,
    color: 'rgba(37, 55, 70, 1)',
  },
}));


const ChatMessageAdd: FC<ChatMessageAddProps> = (props) => {
  const classes = useStyles();
  const { disabled, onSend, ...other } = props;
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { userProfile = {} } = useSelector((state) => state.user);
  const [body, setBody] = useState<string>('');
  const sendKeys = ['enter', 'numpadenter'];

  const { t } = useTranslation();

  const handleAttach = (): void => {
    fileInputRef.current.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setBody(event.target.value);
  };

  const handleSend = (): void => {
    if (!body || isEmpty(body.trim())) {
      return;
    }

    onSend?.(body.trim());
    setBody('');
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (sendKeys.includes(event.code?.toLowerCase()) && !disabled && body.trim().length > 0) {
      handleSend();
    }
  };

  const handleKeyPress = (event: any) => {
    if (sendKeys.includes(event.code?.toLowerCase())) {
      event.preventDefault();
    }
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        // backgroundColor: 'background.paper',
        display: 'flex',
        flexShrink: 0,
        borderRadius: '10px',
      }}
      {...other}
    >
      <CssTextField
        fullWidth
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        onKeyPress={handleKeyPress}
        placeholder={t("nbsChat.searchPlaceholder")}
        value={body}
        size="small"
        color='primary'
        variant="outlined"
        sx={{
          backgroundColor: 'white',
          fontWeight: '700',
          // boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.15)',
        }}
        inputProps={{
          maxLength: 4096,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end"
              sx={{ height: '100%', maxHeight: 'unset', flexDirection: 'column', marginTop: 'auto', borderRadius: '10px' }}>
              <Tooltip title="Send" sx={{ borderRadius: '10px' }}>
                <IconButton
                  sx={{
                    borderRadius: '0px',
                    backgroundColor: body.trim().length < 1 || disabled ? 'transparent' : `${colors.mainColor} !important`,
                    padding: '0.375rem',
                    margin: '0.375rem',
                    marginRight: 0,
                    cursor: 'pointer !important',
                    opacity: 1,
                    '&:disabled': {
                      opacity: 0.3, // Opacity when disabled
                      cursor: 'not-allowed', // Change cursor on disabled state
                    }
                  }}
                  disabled={body.trim().length < 1 || disabled}
                  onClick={handleSend}
                >
                  <SendButton
                    style={{
                      color: body.trim().length < 1 || disabled ? colors.darkGrey : colors.white,
                      cursor: 'pointer !important',
                    }}
                    sx={{
                      fontSize: {
                        xs: '0.875rem',
                        sm: '0.875rem',
                        md: '1rem',
                        lg: '1.1rem',
                        xl: '1.375rem',
                      }
                    }}
                  />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
          classes: { input: classes.resize },
        }}
      />
      <input hidden ref={fileInputRef} type="file" />
    </Box>
  );
};

ChatMessageAdd.propTypes = {
  disabled: PropTypes.bool,
  onSend: PropTypes.func,
};

ChatMessageAdd.defaultProps = {
  disabled: false,
};

export default ChatMessageAdd;
