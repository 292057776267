import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import { Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import { Theme } from '@mui/material';

interface ChatToolButtonProps extends IconButtonProps {
  onClick: any;
  src: any;
  disabled?: boolean;
  tooltipText?: string;
}

const ChatToolButton: FC<ChatToolButtonProps> = (props) => {
  const dispatch = useDispatch();
  const { src, onClick = () => { }, disabled = false, tooltipText = '' } = props;
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(760));
  const isReactElement = React.isValidElement(src);

  const icon = isReactElement ? src : <img style={{ color: 'lightgray', width: '21px', height: '21px' }} src={src} alt="Chat-tool-icons" />;

  return (
    <Tooltip title={tooltipText ? <Typography color="white" fontSize="12px">{tooltipText}</Typography> : ''}>
      <span>
        <IconButton
          disabled={disabled}
          sx={{
            height: '20px',
            width: '20px',
            '&:hover': {
              //   backgroundColor: '#0A0F34',
              //   color: '#EDEDED',
              //   border: '1px solid #EDEDED',
            },
          }}
          onClick={onClick}
          {...props}
        >
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  );
};

ChatToolButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  src: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  tooltipText: PropTypes.string
};

export default ChatToolButton;
