/*eslint-disable*/
import { useEffect, useRef, useState, useCallback } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, useMediaQuery, Theme, Button, Divider, IconButton } from '@material-ui/core';
import { useSelector, useDispatch } from 'src/store';
import useMounted from 'src/hooks/useMounted';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { CloseOutlined } from '@material-ui/icons';



pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface ChatDocumentViewerProps {
  url: any;
  sourceDocuments?: any;
  setIsSplit: (val: boolean) => void;
}

const ChatDocumentViewer: FC<ChatDocumentViewerProps> = (props) => {
  const { url, sourceDocuments, setIsSplit, ...other } = props;
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(760));
  const mounted = useMounted();
  const [inputData, setInputData] = useState()
  const [documentUrl, setDocumentUrl] = useState();
  const {
    activeThreadId
  } = useSelector((state) => state.chat);

  const rootRef = useRef<any>(null);

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [highlightNumber, setHighlightNumber] = useState(0);
  const [pagesWithHighlight, setPagesWithHighlight] = useState(null)


  const highlightedTextTagNumber = useRef(0);
  let textAppend = useRef([]);
  const indices = useRef([]);
  const selectedParaIndices = useRef({ page: -1, start: -1, end: -1 });

  const highlightParagraph = useCallback(
    (text, itemIndex) => {

      // return `<div id= a${pageNumber}:${itemIndex}> <mark>${text}</mark> </div>`
      return `<mark>${text}</mark>`;
    },
    []
  );


  const onDocumentLoadSuccess = (pdfDocument) => {

    const { numPages } = pdfDocument
    setNumPages(numPages);

    // const highlightPageContents = page_content
    //   .filter(([document, _]) => document.page_content)
    //   .map(([document]) => document.page_content);


    let highlightedPages = []

    const start = performance.now();

    const promises = [];

    for (let i = 1; i <= numPages; i++) {
      const promise = pdfDocument.getPage(i).then((page) => {
        return page.getTextContent().then((textContent) => {

          let highlightTextfound = identifyPageAgainstText(sourceDocuments, textContent, i);
          if (highlightTextfound) {
            highlightedPages.push(i)
          }
        });
      });

      promises.push(promise);
    }

    Promise.all(promises)
      .then(() => {

        const distinctPages = [...new Set(highlightedPages)];
        const sortedPages = distinctPages.slice().sort((a, b) => a - b);
        setPagesWithHighlight(sortedPages);
        if (sortedPages.length > 0) {
          setPageNumber(sortedPages[0]);
        }


        const end = performance.now();
        console.log("Total execution time:", end - start);
      })
      .catch((error) => {
        console.error("Error:", error);
      });



  };


  const identifyPageAgainstText = (highlightPageContents, pageContent, pageNum) => {
    let highlightTextfound = false;

    const { items } = pageContent;

    const searchableTextArrays = highlightPageContents
      .map(doc => doc.page_content.split('\n').map(line => line.trim()).filter(f => f));
    console.log("split", searchableTextArrays.length, searchableTextArrays);
    let pageTextStack = []
    if (searchableTextArrays.length > 0) {
      searchableTextArrays.forEach(element => {
        let searchableTextArray = element;
        let idx = pageContent.items.findIndex(item => item.str === searchableTextArray[0]);
        let currentIndex = { start: -1, end: -1 }

        if (idx > -1) {
          currentIndex.start = idx;
          const str = searchableTextArray.shift();
          pageTextStack.push(str);
          idx++;
        }

        while (searchableTextArray.length && idx > -1) {
          if (items[idx].str.trim() === '') {
            idx++;
            continue
          }

          if (items[idx].str === searchableTextArray[0]) {
            idx++;
            pageTextStack.push(searchableTextArray.shift());
          }
          else {
            // if partial match
            let _counter = 0;
            let str = items[idx].str;
            while (searchableTextArray[0].indexOf(str) === 0) {
              _counter = str.length;
              idx++;
              str += items[idx].str;

              if (_counter === searchableTextArray[0].length) {
                break;
              }
            }

            if (_counter === searchableTextArray[0].length) {
              pageTextStack.push(searchableTextArray.shift());
              continue;
            }

            currentIndex.start = -1;
            currentIndex.end = -1;
            searchableTextArray.unshift(...pageTextStack);
            break;
          }
        }

        if (searchableTextArray.length === 0) {
          currentIndex.end = idx - 1;
          //indices.current.push(currentIndex) //we can track indexes at this point as well
          indices.current.push({ page: pageNum, ...currentIndex })
          highlightTextfound = true;
        }
      });
    }

    return highlightTextfound;
  }

  const targetRef = useRef();

  const scrollToHighlight = () => {

    let highlightElement = document.getElementById('5:4');
    if (highlightElement) {
      console.log("SCrolled")
      highlightElement.scrollIntoView({
        behavior: "smooth", // You can use "auto" or "smooth" for the scrolling behavior
        block: "nearest",     // You can use "start", "center", "end", or "nearest" for vertical alignment
        inline: "nearest"   // You can use "start", "center", "end", or "nearest" for horizontal alignment
      });
    }
  }

  const handleScroll = (num) => {
    console.log('PageNum:', num)
    setPageNumber(num)
    //let tagName = "a"+pageNumber+":"+num;
    //const allElements = document.querySelectorAll('*');

    const allElements = document.getElementsByTagName('*');
    console.log('tagName:>>', allElements)



    for (const element of allElements) {
      let tagName = element.id;
      if (tagName) {
        console.log('id>>>>>>>>>>>', tagName);
        let tagPageNum = element.id.split(':')[1];

        if (tagPageNum == num) {
          //let page = element.id.split(':')[0].replace('a', '');
          //console.log('Page:>>', parseInt(page, 10))
          //setPageNumber(parseInt(page, 10))
          let highlightElement = document.getElementById(tagName);
          console.log("SCrolled")
          highlightElement.scrollIntoView({
            behavior: "smooth", // You can use "auto" or "smooth" for the scrolling behavior
            block: "nearest",     // You can use "start", "center", "end", or "nearest" for vertical alignment
            inline: "nearest"   // You can use "start", "center", "end", or "nearest" for horizontal alignment
          });
        }
      }

      // console.log('id>>>>>>>>>>>',element.id);
    }

    // Scroll to the element


    // if (targetRef.current) {
    //   console.log(targetRef);
    //   targetRef.current.scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'start',
    //     inline: 'nearest',
    //   });
    // }
  };

  const navigateForward = () => {
    console.log('Navigate Forward', highlightNumber, "PAge", pageNumber)

    var result = pagesWithHighlight.filter(f => f > pageNumber);
    if (result && result.length > 0) {
      setHighlightNumber(highlightNumber + 1);
      setPageNumber(result[0])
      //scrollToHighlight()
      //handleScroll(result[0]);
    }

  }

  const navigateBack = () => {

    var result = pagesWithHighlight.filter(f => f < pageNumber);
    if (result && result.length > 0) {
      setHighlightNumber(highlightNumber - 1);
      setPageNumber(result[result.length - 1])
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      //scrollToHighlight()
      //handleScroll(result[result.length - 1]);

    }
  }

  const textRenderer = useCallback(
    (textItem) => {
      const indexItem = indices.current.filter(f => f.start === textItem.itemIndex && f.page === pageNumber);
      let hasVal = false;
      if (indexItem && indexItem.length > 0) {
        const { start: iStart, end: iEnd } = indexItem[0];
        selectedParaIndices.current = { start: iStart, end: iEnd, page: pageNumber };
        hasVal = true
      }

      if (selectedParaIndices) {


        let { start, end, page } = selectedParaIndices.current;

        if (start > -1 && page === pageNumber && textItem.itemIndex === start && start <= end) {

          selectedParaIndices.current.start += 1;
          if (textItem.str) {
            if (hasVal) {
              console.log('TagID::::', `${pageNumber}:${textItem.itemIndex}`)
              //return `<mark id= ${pageNumber}:${textItem.itemIndex}> ${highlightParagraph(textItem.str, textItem.itemIndex)}</mark>`
            }
            return highlightParagraph(textItem.str, textItem.itemIndex);
          }
        }
      }

      return textItem.str;

    },
    [[inputData, pageNumber]]
  );

  const onPageLoadSuccess = useCallback(async page => {
    console.log("Page::", page.pageNumber)
    selectedParaIndices.current = { page: -1, start: -1, end: -1 };
    indices.current = []
    textAppend.current = []

    const { items } = await page.getTextContent();

    const pageContents = source_documents
      .filter(([document, _]) => document.metadata.page === page.pageNumber)
      .map(([document]) => document.page_content);

    const searchableTextArrays = pageContents
      .map(element => element.split('\n').map(line => line.trim()).filter(f => f));
    console.log("split", searchableTextArrays.length, searchableTextArrays);

    if (searchableTextArrays.length > 0) {
      searchableTextArrays.forEach(element => {
        let searchTextStack = element

        let idx = items.findIndex(item => item.str === searchTextStack[0]);
        let currentIndex = { start: -1, end: -1 }

        if (idx > -1) {
          currentIndex.start = idx;
          const str = searchTextStack.shift();
          textAppend.current.push(str);
          idx++;
        }

        while (searchTextStack.length && idx > -1) {
          if (items[idx].str === '') {
            idx++;
            continue
          }

          if (items[idx].str === searchTextStack[0]) {
            idx++;
            textAppend.current.push(searchTextStack.shift());
          }
          else {
            currentIndex.start = -1;
            currentIndex.end = -1;
            searchTextStack.unshift(...textAppend.current);
            break;
          }

        }

        if (searchTextStack.length === 0) {
          currentIndex.end = idx - 1;
          indices.current.push(currentIndex)
        }


      });
    }

    console.log("Indices", indices)


  }, []);


  useEffect(() => {

  });





  return (
    <Box
      display="flex"
      flexDirection="column"
      height="73vh"
      alignItems="center"
      color="white">
      <Box
        height="50px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        paddingX={2}>
        <Box
          display="flex"
          justifyContent="center">
          <Button
            onClick={navigateBack}
            color="primary"
            className="btn-newChat"
            variant="outlined"
            sx={{ width: '130px !important' }}>
            Prev Highlight
          </Button>
          <Box width={10} /> { }
          <Button
            onClick={navigateForward}
            color="primary"
            className="btn-newChat"
            variant="outlined"
            sx={{ width: '130px !important' }}>
            Next Highlight
          </Button>
          <Box width={10} /> { }
          <Divider orientation="vertical" flexItem />
          <Box width={10} /> { }
          <Button
            onClick={() => setPageNumber(pageNumber - 1)}
            color="primary"
            className="btn-newChat"
            variant="outlined"
            sx={{ width: '130px !important' }}>
            Prev Page
          </Button>
          <Box width={10} /> { }
          <Button
            onClick={() => setPageNumber(pageNumber + 1)}
            color="primary"
            className="btn-newChat"
            variant="outlined"
            sx={{ width: '130px !important' }}>
            Next Page
          </Button>

          <IconButton onClick={() => setIsSplit(false)}><CloseOutlined /></IconButton>
        </Box>
      </Box>
      <Divider orientation="horizontal" flexItem />
      <Box
        flex="1"
        overflow="auto"
        color="black"
        bgcolor="background.default"
        p={2}>
        {/* <ChatDocument inputData={""} fileUrl= {'http://localhost:3000/testDocument.pdf'}/> */}
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} customTextRenderer={textRenderer} />
        </Document>

      </Box>
      <Divider orientation="horizontal" flexItem />
      {/* <Box
          height="50px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingX={2}>
            <Box
                display="flex"
                justifyContent="center">
                  <Button
                    color="primary"
                    className="btn-newChat"
                    variant="outlined">
                      Previous
                  </Button>
                  <Box width={10} /> {}
                  <Divider orientation="vertical" flexItem />
                  <Box width={10} /> {}
                  <Button
                    color="primary"
                    className="btn-newChat"
                    variant="outlined">
                      Next
                  </Button>
            </Box>
        </Box> */}
    </Box>
  );
};


export default ChatDocumentViewer;
